import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import navs from "config/navs";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "components/shared";
import { classNameJoin } from "utils/lib";
import { useCategoryUnreadCount } from "contexts/CategoryUnreadCountProvider";

interface IProps extends IStyleProps {
  onToggle: () => void;
}

interface IStyleProps {
  toggle: boolean;
}

const GlobalNavigationBar = ({ toggle, onToggle }: IProps) => {
  const user = useRecoilValue(userState);
  const { pathname } = useLocation();
  const domain = pathname.split("/")[1];
  const { categoryUnreadCount } = useCategoryUnreadCount();

  const { mySubId } = useMemo(() => {
    if (!user) {
      return { mySubId: null };
    }
    return { mySubId: user.uuid };
  }, [user]);

  const handleToggleWithStopPropagation = (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    e.stopPropagation();
    onToggle();
  };

  return (
    <>
      <Background toggle={toggle} onClick={onToggle} />
      <GNB toggle={toggle}>
        <div className="h-full w-[230px] bg-white border-r border-secondary-100">
          {navs.map((nav, i) => (
            <Wrapper key={i}>
              {nav.map((item) => {
                const { path, name, icon } = item;

                if (name === "주문관리") {
                  return (
                    <li key={path} onClick={onToggle}>
                      <a
                        href={`${path}#${mySubId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex items-center h-[48px] px-4 text-secondary-700">
                          <Icon
                            className="w-6 text-center"
                            icon={icon as IconProp}
                          />
                          <span className="ml-3">{name}</span>
                        </div>
                      </a>
                    </li>
                  );
                }

                if (name === "의견 보내기") {
                  return (
                    <li key={path} onClick={onToggle}>
                      <a href={path} target="_blank" rel="noreferrer">
                        <div className="flex items-center h-[48px] px-4 text-secondary-700">
                          <Icon
                            className="w-6 text-center"
                            icon={icon as IconProp}
                          />
                          <span className="ml-3">{name}</span>
                        </div>
                      </a>
                    </li>
                  );
                }

                if (path) {
                  return (
                    <li
                      key={path}
                      onClick={
                        name === "문의톡 목록"
                          ? handleToggleWithStopPropagation
                          : onToggle
                      }
                    >
                      <NavLink
                        className={({ isActive }) =>
                          classNameJoin([
                            "flex items-center h-[48px] px-4",
                            isActive || path.indexOf(domain) !== -1
                              ? " text-primary-900 bg-primary-50 font-medium"
                              : "text-secondary-700",
                          ])
                        }
                        to={path}
                      >
                        <Icon
                          className="w-6 text-center"
                          icon={icon as IconProp}
                        />
                        <span className="ml-3">{name}</span>
                        {name === "문의톡 목록" &&
                          categoryUnreadCount.total > 0 && (
                            <span className="ml-auto w-[35px] h-[20px] bg-primary-900 text-white flex items-center mt-0.5 justify-center pr-[1px] pb-[1px] rounded-xl text-sm">
                              {categoryUnreadCount.total > 99
                                ? "99+"
                                : categoryUnreadCount.total}
                            </span>
                          )}
                      </NavLink>

                      {/* {domain === basename && children && (
                          <ul>
                            {children.map(({ path, name }) => (
                              <li key={path}>
                                <NavLink
                                  className={({ isActive }) =>
                                    classNameJoin([
                                      "block pl-[52px] py-2 text-[15px]",
                                      isActive
                                        ? " text-primary-900"
                                        : "text-secondary-700",
                                    ])
                                  }
                                  to={path}
                                  key={path}
                                >
                                  {name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )} */}
                    </li>
                  );
                }

                return null;
              })}
            </Wrapper>
          ))}
        </div>
      </GNB>
    </>
  );
};

const GNB = styled.nav<IStyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  z-index: 999;

  transform: translateX(${(props) => (props.toggle ? "0px" : "-230px")});

  @media (min-width: 1024px) {
    transform: translateX(0px);
  }
`;

const Wrapper = styled.ul`
  position: relative;

  & + & {
    margin-top: 24px;
    padding-top: 24px;

    &:after {
      content: "";
      position: absolute;
      height: 1px;
      width: 80%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #bebebe; // text-secondary-400
    }
  }
`;

const Background = styled.div<IStyleProps>`
  cursor: pointer;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 998;
  transition: all 0.3s;
  visibility: ${(props) => (props.toggle ? "visible" : "hidden")};
  opacity: ${(props) => (props.toggle ? 1 : 0)};

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export default GlobalNavigationBar;
