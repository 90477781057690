import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { useMyPoint } from "utils/helper";
import { userState } from "store/user";
import Notification from "components/Notification";
import { Icon, Thumbnail } from "components/shared";

interface IProps {
  toggle: boolean;
  onToggle: () => void;
}

const GlobalHeader = ({ toggle, onToggle }: IProps) => {
  const user = useRecoilValue(userState);
  const { commaPoint, refetch } = useMyPoint();
  const location = useLocation();
  const navigate = useNavigate();

  const { name, profileUrl } = useMemo(() => {
    if (!user) {
      return {
        name: null,
        profileUrl: null,
      };
    }

    return {
      name: user.name,
      profileUrl: user.profileUrl,
      uuid: user.uuid,
    };
  }, [user]);

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  return (
    <Header className="border-b border-secondary-100 select-none">
      <Hamberger onClick={onToggle}>
        <Icon className="bars" icon={toggle ? "xmark" : "bars"} fontSize={20} />
      </Hamberger>
      <Title>
        <h5
          className="text-xl text-primary-900 cursor-pointer"
          onClick={() => navigate("/broadcast")}
        >
          클릭메이트
        </h5>
        <SubTitle>셀러 관리자</SubTitle>
      </Title>
      <Tools>
        <div className="mr-4 font-bold text-primary-900 hidden md:inline-block">
          <span>{commaPoint}</span>
          <span className="ml-1">P</span>
        </div>
        {/* <Notification user={user} /> */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/profile")}
        >
          <Thumbnail src={profileUrl} />
          <span className="user-name ml-2">{name}</span>
        </div>
      </Tools>
    </Header>
  );
};

const Hamberger = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;

  .bars {
    display: block;

    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: white;
  z-index: 999;
  padding-right: 16px;

  @media (min-width: 1024px) {
    padding: 0 16px;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    transform: translate(0, -50%);
    flex-direction: row;
    align-items: end;
    left: 24px;
  }
`;

const SubTitle = styled.span`
  font-size: 12px;
  margin-left: 0px;

  @media (min-width: 1024px) {
    font-size: 14px;
    display: block;
    margin-left: 12px;
  }
`;

const Tools = styled.div`
  display: flex;
  align-items: center;

  .user-name {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }
`;

export default GlobalHeader;
