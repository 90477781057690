import dayjs from "dayjs";
import { IBroadcastDateTime } from "interfaces/broadcast.interface";
import { ITossPaymentResult } from "interfaces/service.interface";
import { dayOfweekKO } from "./data";

// 길이 > 대소문자 > 숫자 > 특수문자

export const validatePassword = (value: string) => {
  /**
  - 8~18자 체크(8~18자를 입력해주세요)
  - 영문 대소문자 유무 체크(영문 대소문자를 포함해주세요)
  - 숫자 체크(숫자를 포함해주세요)
  - 특수문자 체크(특수문자를 포함해주세요)
  */

  const initial = {
    passwordLength: false,
    passwordIncludeNumber: false,
    passwordSpecialString: false,
    passwordUpperLowercase: false,
    passwordNotIncludeString: false,
  };

  if (!(value.length >= 8 && value.length <= 18)) {
    initial.passwordLength = true;
    return initial;
  }

  if (!/(?=.*[A-Z])/.test(value) || !/(?=.*[a-z])/g.test(value)) {
    initial.passwordUpperLowercase = true;
    return initial;
  }

  if (!/([0-9])/g.test(value)) {
    initial.passwordIncludeNumber = true;
    return initial;
  }

  if (!/([`~₩!@#$%^&*()_+-,.<>/?:;'"[{}\|\=\]])/g.test(value)) {
    initial.passwordSpecialString = true;
    return initial;
  }

  if (/[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]+/gu.test(value)) {
    initial.passwordNotIncludeString = true;
    return initial;
  }

  return initial;
};

/**
 * className 병합
 * @param classNames (string | undefined | null | boolean)[]
 * @returns string
 */
export const classNameJoin = (classNames: (string | undefined | null | boolean)[]) =>
  classNames.filter((className) => !!className).join(" ");

/**
 * 금액에 comma 찍기
 * @param num number | string
 * @returns string
 */
export const numbericComma = (num: number | string) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 * hypen 붙여서 전화번호 만들기
 * @param phone string
 * @returns string
 */
export const phoneHypen = (phone: string) =>
  phone.replace(/([0-9]{3})([0-9]{4})([0-9]{4})/, "$1-$2-$3");

/**
 * 줄바꿈 \n > <br/>
 * @param content string;
 * @returns string
 */
export const nl2br = (content: string) => content.replace(/\n/g, "<br/>");

export const makeNumberic = (str: string | number | null = "0"): number => {
  return parseInt(str!.toString().replace(/,/g, ""));
};

/**
 * object에 지정된 key가 들어있는지 검사
 * @param obj object
 * @param key string
 * @returns boolean
 */
export const hasOwnProperty = (obj: object, key: string) =>
  Object.prototype.hasOwnProperty.call(obj, key);

export const meridiem = (str: string) => {
  return str === "AM" ? "오전" : "오후";
};

/**
 * 라이브 날짜 데이터 포맷팅
 * @param data IBroadcastDateTime[]
 */
export const datetime = (data: IBroadcastDateTime[]) => {
  if (!data.length) return null;

  let startDate = null,
    endDate = null,
    startDay = null,
    endDay = null,
    startTime = "",
    endTime = "",
    hour = 0;

  if (data.length > 0) {
    const start = data[0];
    const sd = dayjs(start.date);
    startDate = sd.format("YYYY.MM.DD");
    startDay = dayOfweekKO[sd.day()];
    startTime = `${start.timeDivision} ${start.time}`;

    const end = data[data.length - 1];
    const ed = dayjs(`${end.date} ${end.time}`).add(1, "hour");
    endDate = ed.format("YYYY.MM.DD");
    endDay = dayOfweekKO[ed.day()];
    const meridiem = ed.format("A");
    const time = ed.format("HH:mm");
    endTime = `${meridiem} ${time}`;

    hour = data.length;
  }

  return {
    day: {
      start: startDay,
      end: endDay,
    },
    date: {
      start: startDate,
      end: endDate,
    },
    range: {
      startTime,
      endTime,
      hour,
    },
  };
};

/**
 * 결제방법
 * @param data ITossPaymentResult
 * @returns string ex) BC 1234
 */
export const getMethod = (data: ITossPaymentResult) => {
  const { method } = data;

  if (method === "카드") {
    const { company, number } = data.card!;
    return `${company} ${number.substring(0, 4)}`;
  }

  return "무통장입금";
};

/**
 * daysFormat
 * @param date string
 * @param format string?
 * @return string ex) 1분 전
 */
const MINUTE = 1000 * 60;
const HOUR = 1000 * 60 * 60;
const DATE = 1000 * 60 * 60 * 24;
export const daysFormat = (date: string, format: string = "YYYY.MM.DD HH:mm:ss") => {
  const millisecond = dayjs(date).valueOf();
  const result = dayjs().subtract(millisecond, "millisecond");
  const datetime = result.valueOf();

  const subtractDate = Math.floor(datetime / DATE);

  if (subtractDate > 7) {
    return dayjs(date).format(format);
  }

  if (datetime > DATE) {
    return Math.floor(datetime / DATE) + "일 전";
  }

  if (datetime > HOUR) {
    return Math.floor(datetime / HOUR) + "시간 전";
  }

  if (datetime > MINUTE) {
    return Math.floor(datetime / MINUTE) + "분 전";
  }

  return Math.floor(datetime / 1000) + "초 전";
};

/**
 * Whale 의 경우 Safari, Chrome 이 전부 뜸
 * 희귀도 우선 순위로 체크됨
 */
export const client = () => {
  const agent = window.navigator.userAgent;

  if (/(Whale)/gi.test(agent)) {
    return "Whale";
  }

  if (/(Safari)/gi.test(agent)) {
    return "Safari";
  }

  if (/(Chrome)/gi.test(agent)) {
    return "Chrome";
  }

  return "None";
};

export const os = () => {
  const agent = window.navigator.userAgent;

  if (/(Mac)/gi.test(agent)) {
    return "Mac";
  }

  if (/(Windows)/gi.test(agent)) {
    return "Windows";
  }

  return "None";
};

export const setLocalStorageCookie = (
  key: string,
  data: { [key: string]: any },
  expires: string
) => {
  data.expires = expires;
  localStorage.setItem(key, JSON.stringify(data));
};

export function getLocalStorageCookie<T = {}>(key: string) {
  const data = localStorage.getItem(key);
  if (!data) return null;

  const json = JSON.parse(data);

  if (dayjs().isAfter(json.expires)) {
    localStorage.removeItem(key);
    return null;
  }

  return json as T;
}

export const mobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Android 감지
  if (/android/i.test(userAgent)) {
    return "A";
  }

  // iOS 감지 (MSStream은 제거)
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "I";
  }

  return null;
};

/**
 * http를 https로 변경하는 함수
 */
export const convertHttpToHttps = (url: string) => {
  return url.replace(/^http:\/\//i, "https://");
};
